// Advanced Five9 Chat

const floatingChatBtnHtml = `
  <style>
    .chat-floating-btn-container {
      display: none;
      height: 60px;
      width: 60px;
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 99999;
      opacity: 0;
      transition: opacity 250ms ease-in-out 0s;
    }
    .chat-floating-btn-container.is--visible {
      display: block;
      opacity: 1;
    }
    .chat-floating-btn-container .chat-floating-btn {
      height: 60px;
      width: 60px;
      color: #fff;
      background-color: #D8F793;
      border-radius: 30px;
      border: 0;
      box-shadow: rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px;
      transition: box-shadow 200ms ease-in-out 0s;
      cursor: pointer;
    }
    .chat-floating-btn-container .chat-floating-btn svg {
      color: inherit;
    }
    .chat-floating-btn-container .chat-floating-btn:hover {
      box-shadow: 0 0 15px rgba(0,0,0,.5);
    }
  </style>
  <button type='button' class='chat-floating-btn' aria-label="live chat">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"/></svg>
  </button>
`;

const chatContainerCss = `
  .chat-container {
    height: 0;
    width: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99999;
    border-radius: 30px;
    overflow: hidden;
    background-color: transparent;
    transition: height 200ms ease-in-out 0s;
  }
  .chat-container.is--visible {
    height: 600px;
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0,0,0,.5);
  }
  .chat-container::after {
    display: block;
    height: 100px;
    width: 100px;
    position: absolute;
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"/></svg>');
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    transform: scale(.5);
    opacity: 0.2;
    animation: chat-animate 1.5s infinite;
  }
  .chat-container::before {
    display: block;
    height: 100px;
    width: 100px;
    position: absolute;
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"/></svg>');
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    transform: scale(.5);
    opacity: 0.2;
    animation: chat-animate 1.5s infinite;
    animation-delay: -0.75s;
  }
  @keyframes chat-animate {
    to {
      transform: scale(1.8);
      opacity: 0
    }
  }
  .chat-container .f9ChatEmbedded {
    height: 100%;
    top: 0;
    border: 0;
    border-top: 3px solid #65758E;
    box-shadow: none;
  }
  .chat-container .chat-toggle-btn {
    position: absolute;
    height: 45px;
    width: 45px;
    line-height: 45px;
    padding: 0;
    top: 10px;
    right: 10px;
    background-color: #4B5DFF;
    color: #fff;
    z-index: 99999;
    border: 0;
    border-radius: 50%;
    transition: background-color 200ms ease-in-out 0s, color 200ms ease-in-out 0s;
  }
  .chat-container .chat-toggle-btn:hover {
    background-color: #D8F793;
    color: #333;
    box-shadow: 0 0 15px rgba(0,0,0,.3);
  }
  .chat-container .chat-toggle-btn .svg-icon {
    margin-top: -3px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
    box-sizing: content-box;
  }
  @media (min-width: 576px) {
    .chat-container {
      bottom: 20px;
      right: 20px;
    }
  }
  @media (max-height: 710px) {
    .chat-container {
      bottom: 0;
      right: 0;
    }
    .chat-container.is--visible {
      height: 100%;
    }
    .chat-container .f9ChatEmbedded {
      height: 100%;
    }
  }
`;

// html for the prechat box
const preChatFormHtml = (emailValue = '') => {
  return `
    <button class="chat-toggle-btn"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path></svg></button>
    <div id="pre-chat-form-wrapper">
      <style>
        #pre-chat-form-wrapper {
          position: relative;
          padding: 20px;
          padding-top: 60px;
          background: #fff;
          z-index: 1;
          overflow-y: scroll;
          height: 100%;
        }
        #vivr-iframe {
          position: relative;
          z-index: 1;
          height: 100%;
        }
        .pre-chat-header {
          text-align: center;
          margin-bottom: 30px;
        }
        .pre-chat-title {
          font-weight: bold;
          fond-size: 36px;
        }
        .chat-prechat-form {
          margin: 0;
          padding: 0;
        }
        .pre-chat-label {
          display: block;
          margin: 0;
          margin-bottom: 15px;
        }
        .pre-chat-label .invalid-text {
          display: none;
          font-size: .9em;
          color: #fb4f16;
        }
        .pre-chat-label.is-invalid .invalid-text {
          display: inline-block;
        }
        .pre-chat-text-label {
          margin: 0;
          margin-bottom: 3px;
          font-size: .9rem;
        }
        .pre-chat-field {
          width: 100%;
          appearance: none;
          position: relative;
          padding: 5px 10px;
          background: #fff;
          border: 1px solid #cccccc;
          min-height: 38px;
          line-height: inherit;
          border-radius: 7px;
        }
        .pre-chat-field.is-invalid {
          border-color: #fb4f16;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: right calc(.375em + .1875rem) center;
          background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        }
        .pre-chat-field.is-invalid:focus-visible {
          border-color: #ccc;
        }

        .pre-chat-language {
          display: flex;
          margin-bottom: 5px;
        }
        .pre-chat-language .language-btn {
          width: calc(50% - 5px);
        }
        .pre-chat-language .language-btn:first-child {
          margin-right: 10px;
        }
        .pre-chat-language .language-btn input[type='radio'] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        .pre-chat-language .language-btn label {
          display: inline-block;
          padding: 5px 5px;
          width: 100%;
          text-decoration: none;
          font-weight: 600;
          color: #458cca;
          border: 1px solid #458cca;
          background-color: transparent;
          border-radius: 4px;
          -webkit-transition: all 100ms ease-in-out;
          transition: all 100ms ease-in-out;
          text-align: center;
        }
        .pre-chat-language .language-btn label:hover {
          color: #ffffff;
          background-color: #458cca;
        }
        .pre-chat-language .language-btn input[type='radio']:checked+label {
          color: #ffffff;
          background-color: #458cca;
        }

        .pre-chat-start-btn {
          font-family: inherit;
          font-size: inherit;
          display: inline-block !important;
          margin: 10px auto 0 auto !important;
          padding: 10px 8px !important;
          width: 100% !important;
          text-decoration: none !important;
          font-weight: 600 !important;
          line-height: 1.6 !important;
          color: #fff !important;
          border: 0 !important;
          background-color: #4B5DFF !important;
          background-image: none !important;
          border-radius: 7px !important;
          box-shadow: none !important;
          text-shadow: none !important;
        }
        .pre-chat-start-btn:hover {
          background-color: #364AFF !important;
        }
        @media (min-width: 576px) {
          #pre-chat-form-wrapper {
            padding: 40px;
            padding-top: 60px;
          }
        }
      </style>

      <div class="pre-chat-header">
        <h4 class="pre-chat-title">Welcome!</h4>
        <p class="pre-chat-texty mb-0">Before we get started, please fill out the details below.</p>
      </div>
      <form id="pre-chat-form" class='chat-prechat-form'>
        <label class="pre-chat-label">
          <p class="pre-chat-text-label">Select Language <em class="invalid-text">- is required</em></p>
          <div class="pre-chat-language">
            <div class="language-btn">
              <input class="required" type="radio" id="language-english" name="language" value="english" required />
              <label for="language-english">English</label>
            </div>
            <div class="language-btn">
              <input class="required" type="radio" id="language-spanish" name="language" value="spanish" required />
              <label for="language-spanish">Spanish</label>
            </div>
          </div>
        </label>
        <label class="pre-chat-label">
          <p class="pre-chat-text-label">Email Address <em class="invalid-text">- is required</em></p>
          <input class="pre-chat-field required" type="email" name="email" aria-label="text email" value="${emailValue}" />
        </label>
        <button class='pre-chat-start-btn' type='button'>Start Chat</button>
      </form>
    </div>
    <iframe id="vivr-iframe" style="display:none;" width="100%" height="600" scrolling="no"></iframe>
  `;
};

// custom text/messaging and translation
const messagerCustomText = {
  en: {}, // enter English custom text if any
  es: {
    formErrorInvalidEmail: 'El correo electrónico no es válido',
    formErrorInvalidQuestion: 'La pregunta no es válida',
    formErrorInvalidName: 'El nombre no es válido',
    headerText: '¿Cómo podemos ayudar?',
    inputPlaceholder: 'Escriba un mensaje...',
    inputPlaceholderBlocked: 'Escriba un mensaje...',
    locationNotSupported:
      'Su navegador no admite los servicios de ubicación o están desactivados. Por favor, escriba su ubicación.',
    locationServicesDenied:
      'Este sitio web no puede acceder a su ubicación. Permita el acceso en la configuración o escriba su ubicación.',
    shareLocation: 'Compartir Ubicación',
    uploadDocument: 'Adjuntar Archivo',
    uploadPhoto: 'Agregar Imagen',
    soundNotification: 'Habilitar Sonido',
    printTranscipt: 'Imprimir Transcripción',
    startConversation: 'Iniciar Conversación',
    conversationTerminated: 'La conversación ha terminado.',
    cancelConversation: 'Cancelar',
    closeConfirmationMessage: '¿Está seguro de que quiere terminar la conservación?',
    closeConfirmationCancel: 'Cancelar',
    closeConfirmationEndChat: 'Terminar la Conversación',
    welcomeHeader: 'Bienvenido',
    welcomeDescription: 'Antes de comenzar, por favor complete los detalles que se encuentran más abajo.',
    languageContinue: 'Continuar',
    languageCancel: 'Cancelar',
    afterHoursHelpText: 'Fuera del horario laboral',
    emailContentLabel: 'Contenido',
    afterHoursEmailHelpText: 'Fuera del horario laboral, el formulario de correo electrónico está disponible',
    emailContentPlaceholder: 'Contenido...',
    outOfBusinessHoursMessageTitle: 'Fuera del horario laboral',
    afterHoursEmailSentSuccess: '¡Éxito!',
    afterHoursEmailSentMessage: 'El correo electrónico ha sido enviado.',
    afterHoursEmailModalClose: 'Cerrar',
    afterHoursStartNew: 'Iniciar Nuevo',
    afterHoursSendEmail: 'Enviar Correo Electrónico',
    afterHoursSendEmailCancel: 'Cancelar',
    chatEndedMessage: 'Su sesión de conversación ha terminado',
    downloadTranscript: 'Descargar Transcripción',
    clearHistory: 'Borrar Historial',
    clearHistoryMessage: 'Su historial se borrará en {minutos}',
    startNewChat: 'Iniciar Nueva Conversación',
    closeWindow: 'Cerrar Ventana',
    endChatconfirmationHeader: 'Terminar Conversación',
    isRequiredText: 'es obligatorio',
    isTypingText: 'está escribiendo',
    areTypingText: 'están escribiendo',
    unknownUser: 'Usuario Desconocido',
    startSurvey: 'Iniciar Encuesta',
    optionalText: 'Texto Opcional',
    postChatSurveyHeaderText: 'Texto de Encabezado de la Encuesta de Publicación Posterior a la Conversación',
    postChatSurveyLabelText: 'Texto de Etiqueta de la Encuesta Posterior a la Conversación',
    postChatSurveyInputPlaceHolderText: 'Texto de Marcador de Posición de Entrada de Encuesta Posterior a la Conversación',
    postChatSurveyCancelButtonText: 'Cancelar',
    postChatSurveySubmitButtonText: 'Enviar',
  },
};

// custom system messaging and translation
const systemMessages = {
  en: {}, // enter English custom text if any
  es: {
    conversationCreated: 'Gracias por contactarnos.',
    participantAccepted: 'Usted ha sido conectado con un agente.',
    participantJoined: 'Un participante se ha unido a la conversación.',
    participantLeft: 'Un participante ha abandonado la conversación.',
    transferredToParticipant: 'La conversación ha sido transferida a {name}.',
    transferredToGroup: 'Esa conversación ha sido transferida al grupo {group}.',
    conversationTerminated: 'La conversación ha terminado.',
  },
};

// return capture fields depending if email needs to be collected
const captureFields = (lang, collectEmail = true) => {
  const defaultFields = [
    { k: 'name', l: `${lang === 'es' ? 'Nombre' : 'Name'}`, p: '' },
    { k: 'Phone', l: `${lang === 'es' ? 'Teléfono' : 'Phone'}`, p: '' },
    { k: 'Question', l: `${lang === 'es' ? 'Pregunta' : 'Question'}`, p: '' },
  ];

  // collect only on marketing website
  const emailField = { k: 'email', l: `${lang === 'es' ? 'Correo Electrónico' : 'Email Address'}`, p: '' };

  if (collectEmail) {
    defaultFields.splice(1, 0, emailField);
  }

  return defaultFields;
};

// mapping chat profile and domains
const chatProfiles = [
  {
    profile: 'Chat_U', // default profile if domain is not listed
    domains: null,
  },
  {
    profile: 'VIVR',
    domains: [
      'login.certus.com',
      'americansafetycouncil.com',
      'amersc.com',
      'certus.com',
      'course.uceusa.com',
      'fusion.certus.com',
      'home.uceusa.com',
      'myaccount.uceusa.com',
    ],
  },
  {
    profile: 'Chat_AAA',
    domains: ['aaadriverprogram.com'],
  },
  {
    profile: 'Chat_DS',
    domains: [
      'delawaredriver.com',
      'help.americansafetycouncil.com',
      '123driving.com',
      'adischool.com',
      'californiadriveredcourse.com',
      'trafficschooltogo.com',
      'delawaredriver.com',
      'dmvflorida.org',
      'firsttimedriver.com',
      'floridaboatingcourse.com',
      'floridadrivingrecord.com',
      'floridafuntrafficschool.com',
      'floridaonlinetrafficschool.com',
      'indianadriver.com',
      'joshuaslaw.com',
      'maturedrivercourse.com',
      'michigandriverimprovement.com',
      'missourionlinedriverimprovement.com',
      'oklahomadriver.com',
      'onlinedefensivedriving.com',
      'onlinetraffic.com',
      'safemotorist.com',
      'stateofflorida.com',
      'teendrivereducation.com',
      'tennesseeonlinetrafficschool.com',
      'texasadultdriver.com',
      'texasdrivingschool.com',
      'trafficschooltogo.com',
      'virginiaonlinedriverimprovement.com',
    ],
  },
  {
    profile: 'Chat_NYSC',
    domains: ['newyorksafetycouncil.com'],
  },
  {
    profile: 'Chat_PS',
    domains: [
      'floridaparentingclass.com',
      'floridainsurancelicense.com',
      'floridaonlinecosmetology.com',
      'floridaonlinetattoolicense.com',
      'ncinsurancelicense.com',
    ],
  },
  {
    profile: 'Chat_SS',
    domains: [
      'bassetcertification.org',
      'cannabiseducationcenter.com',
      'efoodcard.com',
      'efoodhandlers.com',
      'foodhandlercardonline.com',
      'illinoisbassetcertification.com',
      'sellerserveronline.com',
      'tabccertificationprogram.com',
      'tabctraining.org',
      'washingtonalcoholtraining.com',
    ],
  },
  {
    profile: 'Chat_WS',
    domains: [
      'abcosha.com',
      'agrilifeirrigation.com',
      'iecoshatraining.com',
      'oshacampusonline.com',
      'oshaeducationcenter.com',
      'usfosha.com',
      'ustoperatortraining.com',
      'verify.uceusa.com',
    ],
  },
];

const f9chat = {
  chatScript: document.getElementById('five9_chat'),
  userDetails: document.querySelector('.user-bar .username'),
  userEmail: '',
  preChatForm: null,
  domain: 'certus.com', // default for localhost development
  profile: 'Chat_U', // default chat profile if domain is not mapped to a chat profile
  useFloatingBtn: false,
  usePreChatForm: false,
  collectEmail: true,
  chatContainer: null,
  chatFloatingBtnContainer: null,
  chatInitialized: false,

  init: () => {
    f9chat.collectEmail = f9chat.userDetails ? false : true;
    f9chat.useFloatingBtn = f9chat.chatScript && f9chat.chatScript.dataset.floatingBtn === 'true';

    const userDetails = f9chat.userDetails?.textContent; // contains the name and email text
    const userEmail = userDetails?.match(/\((.*)\)/)?.pop(); // grabs the actual email between the parenthesis

    f9chat.userEmail = userEmail;

    // set domain value and profile if not in locahost development
    if (!window.location.hostname.includes('localhost')) {
      // all subdomains we need to remove
      const removeSubdomain = /((dl|ml)?(www|www1|shop|cart|store))\./;
      // all subdomains we need to remove the dl/ml prefix
      const removePrefix = /((dl|ml)?(login|course|fusion|home|myaccount|verify|help))\./;

      // set domain value after cleaning it up
      f9chat.domain = window.location.hostname.replace(removeSubdomain, '');
      f9chat.domain = removePrefix.test(f9chat.domain) ? f9chat.domain.replace(/(dl|ml)/, '') : f9chat.domain;

      // set chat profile to use for initialization
      const chatProfile = chatProfiles.find((profile) => profile.domains?.includes(f9chat.domain));
      f9chat.profile = chatProfile ? chatProfile.profile : f9chat.profile;
    }

    // used for testing localhost, you can set the profile you want to test
    // comment out below code after testing
    // f9chat.domain = 'certus.com';
    // f9chat.profile = 'VIVR';
    // end code for testing

    // if profile is VIVR then use prechat form
    f9chat.usePreChatForm = f9chat.profile === 'VIVR';

    // creating chat container div and CSS
    (function () {
      const div = document.createElement('div');
      div.id = 'chatWidgetContainer';
      div.setAttribute('class', 'chat-container');
      div.innerHTML = f9chat.usePreChatForm
        ? preChatFormHtml(userEmail)
        : '<button class="chat-toggle-btn"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path></svg></button>';
      document.body.appendChild(div);
      // adding CSS for chat
      const style = document.createElement('style');
      style.innerHTML = chatContainerCss;
      document.body.appendChild(style);
    })();

    // setting prechat form element
    f9chat.preChatForm = document.getElementById('pre-chat-form');

    f9chat.chatContainer = document.getElementById('chatWidgetContainer');
    f9chat.chatContainer.querySelector('.chat-toggle-btn').addEventListener('click', f9chat.hideChatWindow, true);
    f9chat.appendFloatingChatBtn();

    if (f9chat.useFloatingBtn) {
      console.log('Using Floating Button');
      f9chat.hideChatWindow();
    }
  },

  // mounting floating livechat button
  appendFloatingChatBtn: () => {
    const div = document.createElement('div');
    div.setAttribute('class', 'chat-floating-btn-container');
    div.innerHTML = floatingChatBtnHtml;
    document.body.appendChild(div);

    f9chat.chatFloatingBtnContainer = document.querySelector('.chat-floating-btn-container');

    const floatingChatBtn = document.querySelector('.chat-floating-btn');
    // adding click event listeners for showing the prechat window
    floatingChatBtn.addEventListener('click', f9chat.startChat, true);
  },

  showChatWindow: () => {
    console.log('Showing Chat Window!');

    f9chat.chatContainer.classList.add('is--visible');
    f9chat.chatFloatingBtnContainer.classList.remove('is--visible');
  },

  hideChatWindow: () => {
    console.log('Hiding Chat Window!');

    f9chat.chatContainer.classList.remove('is--visible');
    f9chat.chatFloatingBtnContainer.classList.add('is--visible');
  },

  initChat: () => {
    // workaround for initializing the chat on demand
    // instead of loading the script via a script tag, we fetch the script and use the JS inside an inline script tag
    // this ensure that we only trigger the initialization when the base script is loaded/ready
    const chatScriptUrl = 'https://cdn.prod.us.five9.net/static/stable/chat/wrapper/index.js';

    fetch(chatScriptUrl)
      .then((res) => {
        if (res.status !== 200 && res.status < 500) {
          console.log('Cant fetch Five9 script, status code: ' + res.status);
          return res.json();
        }
        if (res.status >= 500) {
          console.log('Cant fetch Five9 script, status code: ' + res.status);
          return;
        }
        return res.text();
      })
      // only initialize the chat when the main chat script is fetched
      .then((data) => {
        (function () {
          const el = document.createElement('script');
          el.id = 'five9-chat';
          el.type = 'text/javascript';
          el.innerHTML = data;
          let s = document.getElementsByTagName('script');
          s = s[s.length - 1];
          s.parentNode.insertBefore(el, s);
        })();

        f9chat.showChatWindow();

        // this is the modified version of initialization config from Five9
        F9.Chat.Wrapper.init({
          cdn: 'prod',
          useBusinessHours: true,
          languages: {
            enabled: true,
            backgroundColor: '#65758e',
            defaultToBrowserLocale: true,
            allowUnconfiguredLanguages: false,
            languages: [
              { label: 'English', code: 'en', typeLang: 'preferred', default: true },
              { label: 'Spanish', code: 'es', typeLang: 'additional', default: false },
            ],
          },
          l10n: {
            en: {
              messenger: { customText: {} },
              systemMessages: {
                transferredToParticipant: 'The chat has been transferred to {name}.',
                transferredToGroup: 'That chat has been transferred to group {group}.',
              },
              captureFields: captureFields('en', f9chat.collectEmail),
            },
            es: {
              messenger: { customText: { ...messagerCustomText.es } },
              systemMessages: { ...systemMessages.es },
              captureFields: captureFields('es', f9chat.collectEmail),
            },
          },
          prepopulatedFields: [
            { k: 'campaign', v: f9chat.profile },
            { k: 'Chat.domain', v: f9chat.domain },
            { k: 'GroupId', v: f9chat.readCookie('_up4gid') },
            { k: 'CouponCode', v: f9chat.readCookie('_up4cc') },
            ...(!f9chat.collectEmail ? [{ k: 'email', v: f9chat.userEmail }] : []), // use email value from page
          ],
          messenger: {
            integrationId: '667f3a8d5d880336c8c69068',
            soundNotificationEnabled: true,
            transcriptPrintingEnabled: false,
            menuItems: { imageUpload: true, fileUpload: true, shareLocation: false },
            embedded: true,
            setViewportScale: false,
            containerId: 'chatWidgetContainer', // container div for embedded chat
            browserStorage: 'sessionStorage',
            hideWidgetAfterBusinessHours: false,
            scheduleCallback: {
              isCallbackEnabled: false,
              requestCallbackList: '',
              customConfirmationMessage: 'We will call you as soon as we can at [PHONE]',
              web2CampaignAPIHost: '',
            },
            fixedHeader: true,
            displayStyle: 'embedded',
            customColors: { brandColor: '65758e', conversationColor: '0099ff', actionColor: '0099ff' },
            carouselType: 'default',
            // customCssUrl: 'https://cdn.certus.com/global/css/five9.css', // if we need custom CSS
          },
          clearMessagesTimeout: 3,
        });
      })
      .catch(function (err) {
        console.error('Error: ' + err);
      });
  },

  initVIVR: () => {
    if (!f9chat.isPreChatValid(f9chat.preChatForm)) {
      // dont initializing chat if it has been initialized before
      console.log('Prechat form is missing information.');
      return;
    }

    // console.log('Prechat form is valid...');

    // get form data and create an object map
    const rawFormData = new FormData(f9chat.preChatForm);
    const preChatForm = {};
    for (let [key, value] of rawFormData) {
      preChatForm[key] = value;
    }

    // Construct the URL with query parameters
    const baseURL = 'https://api.five9.com/ivr/1/128193/campaigns/300000000000341/new_ivr_session';
    // this callbackUrl can be hosted in our CDN as well and is optional
    const callbackUrl =
      'https://storage.googleapis.com/quickassist/customers/AmericanSafetyCouncil/realtimetranslation/errorpage.html';
    const vivrURL = `${baseURL}?form_domain=${f9chat.domain}&form_email=${encodeURIComponent(
      preChatForm.email
    )}&form_userAgent=${encodeURIComponent(navigator.userAgent)}&form_language=${encodeURIComponent(
      preChatForm.language
    )}&__CALLBACK_URL__=${callbackUrl}`;
    //&form_name=${encodeURIComponent(name)}&form_phone=${encodeURIComponent(phone)}&form_question=${encodeURIComponent(question)}

    // show VIVR iframe
    const vivrIframe = document.getElementById('vivr-iframe');
    const preChatWrap = document.getElementById('pre-chat-form-wrapper');
    vivrIframe.onload = function () {
      // Set a delay (e.g., 2000 milliseconds = 2 seconds)
      setTimeout(function () {
        vivrIframe.style.display = 'block';
      }, 2000);
    };
    preChatWrap.style.display = 'none';
    vivrIframe.src = vivrURL;

    // Show the iframe and hide the spinner after a delay once the iframe has loaded
  },

  startChat: () => {
    if (f9chat.chatInitialized) {
      f9chat.chatFloatingBtnContainer.classList.remove('is--visible');
      f9chat.chatContainer.classList.add('is--visible');

      // dont initializing chat if it has been initialized before
      return;
    }

    // use marketing website chat experience
    if (!f9chat.usePreChatForm) {
      // initialize and show chat widget
      f9chat.initChat();
    }
    // use VIVR experience and pre chat form
    else {
      f9chat.showChatWindow();

      // set eventListener for prechat submit button to initialize VIVR chat
      document.querySelector('.pre-chat-start-btn').addEventListener('click', f9chat.initVIVR, true);

      f9chat.validateOnBlur(f9chat.preChatForm);
    }

    f9chat.chatInitialized = true;
  },

  validateOnBlur: function (theForm) {
    if (!theForm) {
      return false;
    }
    // realtime form fields validation using blur event
    const fields = theForm.querySelectorAll('.required');
    for (let field of fields) {
      const type = field.type;
      if (type === 'email') {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            const re =
              /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(x)) {
              field.classList.add('is-invalid');
              field.closest('.pre-chat-label').classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
              field.closest('.pre-chat-label').classList.remove('is-invalid');
            }
          },
          true
        );
      } else if (type === 'tel') {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
            if (!re.test(x)) {
              field.classList.add('is-invalid');
              field.closest('.pre-chat-label').classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
              field.closest('.pre-chat-label').classList.remove('is-invalid');
            }
          },
          true
        );
      } else {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            if (!x || x.trim() == '') {
              field.classList.add('is-invalid');
              field.closest('.pre-chat-label').classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
              field.closest('.pre-chat-label').classList.remove('is-invalid');
            }
          },
          true
        );
      }
    }
  },

  isPreChatValid: function (theForm) {
    if (!theForm) {
      return false;
    }
    // form fields validation during submission
    const fields = theForm.querySelectorAll('.required');
    let isFormValid = true;
    for (let field of fields) {
      const type = field.type;
      if (type === 'email') {
        const x = field.value;
        const re =
          /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(x)) {
          field.classList.add('is-invalid');
          field.closest('.pre-chat-label').classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
          field.closest('.pre-chat-label').classList.remove('is-invalid');
        }
      } else if (type === 'tel') {
        const x = field.value;
        const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
        if (!re.test(x)) {
          field.classList.add('is-invalid');
          field.closest('.pre-chat-label').classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
          field.closest('.pre-chat-label').classList.remove('is-invalid');
        }
      } else if (type === 'radio') {
        const selectedRadio = document.querySelector(`input[name=${field.name}]:checked`);
        const x = selectedRadio?.value;
        if (!x || x.trim() == '') {
          field.classList.add('is-invalid');
          field.closest('.pre-chat-label').classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
          field.closest('.pre-chat-label').classList.remove('is-invalid');
        }
      } else {
        const x = field.value;
        if (!x || x.trim() == '') {
          field.classList.add('is-invalid');
          field.closest('.pre-chat-label').classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
          field.closest('.pre-chat-label').classList.remove('is-invalid');
        }
      }
    }
    return isFormValid;
  },

  readCookie: (name) => {
    let nameEQ = encodeURI(name) + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return decodeURI(c.substring(nameEQ.length, c.length));
    }
    return null;
  },
};

f9chat.init();

// need to call this after the main JS is loaded on the page
// this function is what the website is calling
const StartLiveChat = () => {
  f9chat.startChat();
};
